import { createFeatureSelector, createSelector } from '@ngrx/store';
import { flatMap, isNil } from 'lodash-es';

import { ISelectGroupOption } from '@locumsnest/components/src/lib/interfaces';

import { ISpecialty, ISpecialtyEntity } from '../../interfaces/api/specialty-entity';
import { ISubSpecialty } from '../../interfaces/specialty';
import { ISpecialtyFeatureState, ISpecialtySharedState } from './interfaces';
import { loadingAdapter, paginationAdapter } from './specialty.adapter';
import { selectAll } from './specialty.reducer';

export const getSubSpecialty = (specialtyId: number) => (subSpecialties: ISubSpecialty[]) =>
  subSpecialties.find((specialty) => specialty.id === specialtyId);

export const getAllSpecialtySubspecialtyOptions = (specialties: ISpecialty[]) =>
  flatMap(specialties, (specialty) =>
    specialty.specialties.map((subspecialty) => ({
      id: subspecialty.id,
      name: subspecialty.title,
      category: specialty.title,
    }))
  );

export const getAllSubSpecialtyOptions = (subSpecialties: ISubSpecialty[]) =>
  subSpecialties.map((subSpecialty) => ({ id: subSpecialty.id, name: subSpecialty.title }));

export const getSpecialtyOptions =
  (specialtyId) =>
  (specialties: ISpecialty[]): ISelectGroupOption[] => {
    let returnObj = null;
    if (specialties.length === 0) {
      return [];
    }
    if (isNil(specialtyId) || isNaN(specialtyId)) {
      returnObj = flatMap(specialties, (specialty) => Object.values(specialty.specialties)).map(
        (subspecialty) => {
          const specialtyObj = specialties.find(
            (specialty) => specialty.id === subspecialty.category
          );
          return {
            id: subspecialty.id,
            name: subspecialty.title,
            category: specialtyObj.title,
            categoryName: specialtyObj.title,
          };
        }
      );
    } else {
      const specialtyObj = specialties.find((specialty) => specialty.id === specialtyId);
      returnObj = specialtyObj.specialties.map((subspecialty) => ({
        id: subspecialty.id,
        name: subspecialty.title,
        category: specialtyObj.title,
        categoryName: specialtyObj.title,
      }));
    }

    return returnObj;
  };

export const getSpecialty = (specialtyId: number) => (specialties: ISpecialtyEntity[]) =>
  specialties.find((specialty) => specialty.id === specialtyId);

export const getSpecialtyNamesByIds =
  (specialtyIds: number[]) => (specialties: ISpecialtyEntity[]) =>
    specialties.filter((x) => specialtyIds.indexOf(x.id) >= 0).map((specialty) => specialty.title);

export const getSpecialtyIds = (specialties: ISpecialtyEntity[]) =>
  specialties.map((specialty) => specialty.id);

export const getSubSpecialtyOptions =
  (subSpecialtyIds: number[]) => (subSpecialties: ISelectGroupOption[]) =>
    subSpecialties.filter(({ id }) => subSpecialtyIds.indexOf(id) > -1);

/**
 * Create Feature Selectors based on the above selectors
 */
export const selectSpecialtyState = createFeatureSelector<
  ISpecialtyFeatureState & ISpecialtySharedState
>('specialties');
export const selectSpecialtyEntityState = createSelector(
  selectSpecialtyState,
  (state) => state.entityState
);

export const selectAllSpecialties = createSelector(selectSpecialtyEntityState, selectAll);

export const selectAllSpecialtyIds = createSelector(selectAllSpecialties, getSpecialtyIds);

export const selectAllSpecialtyOptions = createSelector(selectAllSpecialties, (specialties) =>
  specialties.map((specialty) => ({ id: specialty.id, name: specialty.title }))
);

export const selectAllSpecialtySubspecialtyOptions = createSelector(
  selectAllSpecialties,
  getAllSpecialtySubspecialtyOptions
);

export const selectAllSubSpecialtyOptions = getAllSubSpecialtyOptions;

export const selectSpecialtyOptions = (specialtyId) => getSpecialtyOptions(specialtyId);

export const selectSubSpecialtyOptions = (subSpecialtyIds, specialtyId = null) =>
  createSelector(selectSpecialtyOptions(specialtyId), getSubSpecialtyOptions(subSpecialtyIds));

export const selectSpecialty = (specialtyId) =>
  createSelector(selectAllSpecialties, getSpecialty(specialtyId));

export const selectSpecialtyNamesByIds = (specialtyIds: number[]) =>
  createSelector(selectAllSpecialties, getSpecialtyNamesByIds(specialtyIds));

export const specialtyPaginationSelectors = paginationAdapter.paginationSelectors(
  selectSpecialtyState,
  loadingAdapter,
  'pagination',
  'entityState',
  false
);
