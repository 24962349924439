import { createFeatureSelector, createSelector } from '@ngrx/store';
import {
  IPreferredSiteEntityState,
  IPreferredSiteFeatureState,
  IPreferredSiteSharedState,
} from './interfaces';
import { loadingAdapter, paginationAdapter } from './preferred-site.adapter';
import { selectAll, selectAllTemp } from './preferred-site.reducer';

export const getEntityState = (state: { entityState: IPreferredSiteEntityState }) =>
  state.entityState;

export const getTempState = (state: { tempState: IPreferredSiteEntityState }) => state.tempState;

export const getPreferredSitesIds = (state: IPreferredSiteEntityState) =>
  (state.ids as number[]).map((id: number) => state.entities[id].site);

/**
 * Create Feature Selectors based on the above selectors
 */
export const selectPreferredSiteState =
  createFeatureSelector<IPreferredSiteFeatureState & IPreferredSiteSharedState>('preferredSites');

// Entity State
export const selectPreferredSiteEntityState = createSelector(
  selectPreferredSiteState,
  getEntityState
);

export const selectAllPreferredSites = createSelector(selectPreferredSiteEntityState, selectAll);

export const selectAllPreferredSitesIds = createSelector(
  selectPreferredSiteEntityState,
  getPreferredSitesIds
);

// Temp State
export const selectPreferredSiteTempState = createSelector(selectPreferredSiteState, getTempState);

export const selectAllPreferredSitesTemp = createSelector(
  selectPreferredSiteTempState,
  selectAllTemp
);

export const selectAllPreferredSitesIdsTemp = createSelector(
  selectPreferredSiteTempState,
  getPreferredSitesIds
);

export const loadingStateSelectors = loadingAdapter.getSelectors(selectPreferredSiteState);
export const preferredSitePaginationSelectors = paginationAdapter.paginationSelectors(
  selectPreferredSiteState,
  loadingAdapter,
  'pagination',
  'entityState',
  false
);
