import { Injectable } from '@angular/core';
import { Action, select, Store } from '@ngrx/store';
import { groupBy } from 'lodash-es';
import { combineLatest, merge, Observable, of } from 'rxjs';
import { map, mergeMap, switchMap } from 'rxjs/operators';

import { ISelectGroupOption } from '@locumsnest/components';
import { FilterStateService } from '@locumsnest/core';
import { MicroAppService } from '@locumsnest/core/src/lib/micro-app/micro-app.service';
import {
  distinctCollectionUntilChangedByKey,
  filterNonEmpty,
} from '@locumsnest/core/src/lib/ngrx/operators';

import { PRODUCT_CODES } from '../../core/constants';
import { ISpecialtyEntity } from '../../interfaces/api/specialty-entity';
import { PreferredProfessionSpecialtyService } from '../../preferred-profession-specialty/+state/preferred-profession-specialty.service';
import { ProfessionSpecialtyService } from '../../profession-specialty/+state/profession-specialty.service';
import { SubSpecialtyService } from '../../sub-specialty/+state/sub-specialty.service';
import { intersectNonEmpty } from './../../../../../../libs/core/src/lib/helpers/util';
import { ISpecialtyService } from './interfaces';
import {
  ResetSpecialtyPaginationMessage,
  SpecialtyPaginationMessages,
  UpsertMultipleMessage,
  UpsertSpecialtyPageMessage,
} from './specialty.messages';
import { SpecialtyPersistenceService } from './specialty.persistence.service';
import {
  getAllSpecialtySubspecialtyOptions,
  selectAllSpecialtyIds,
  selectAllSpecialtyOptions,
  selectSpecialty,
  selectSpecialtyEntityState,
  selectSpecialtyNamesByIds,
  selectSubSpecialtyOptions,
  specialtyPaginationSelectors,
} from './specialty.selectors';

@Injectable({
  providedIn: 'root',
})
export class SpecialtyService
  extends FilterStateService<
    ISpecialtyEntity,
    UpsertSpecialtyPageMessage,
    ResetSpecialtyPaginationMessage,
    UpsertMultipleMessage
  >
  implements ISpecialtyService
{
  protected readonly scope = [
    PRODUCT_CODES.MATCH,
    PRODUCT_CODES.LINK,
    PRODUCT_CODES.INTELLIGENCE,
    PRODUCT_CODES.COMMUNITY,
  ];

  constructor(
    protected store: Store,
    protected persistenceService: SpecialtyPersistenceService,
    private preferredProfessionSpecialtyService: PreferredProfessionSpecialtyService,
    private professionSpecialtyService: ProfessionSpecialtyService,
    private subSpecialtyService: SubSpecialtyService,
    protected microAppService: MicroAppService
  ) {
    super();
  }

  get paginationMessages() {
    return SpecialtyPaginationMessages;
  }

  get paginationSelectors() {
    return specialtyPaginationSelectors;
  }

  get entityStateSelector() {
    return selectSpecialtyEntityState;
  }

  get upsertMultipleMessage() {
    return UpsertMultipleMessage;
  }

  loadWithDependencies(
    loadSubSpecialties = true,
    loadPreferences = true,
    loadSpecialtyProfessions = true
  ) {
    const actions: Observable<Action>[] = [this.load()];
    if (loadPreferences) actions.push(this.preferredProfessionSpecialtyService.load());
    if (loadSpecialtyProfessions) actions.push(this.professionSpecialtyService.load());
    if (loadSubSpecialties) actions.push(this.subSpecialtyService.load());

    return merge(...actions);
  }

  getOne(id: number) {
    return this.store.pipe(select(selectSpecialty(id)));
  }

  getAll() {
    return super.getAll().pipe(filterNonEmpty, distinctCollectionUntilChangedByKey('id'));
  }

  getAllWithSubSpecialties() {
    return this.getAll().pipe(
      mergeMap((specialties) =>
        this.subSpecialtyService.getAll().pipe(
          map((subSpecialties) => {
            const subspecialtyGroups = groupBy(subSpecialties, 'category');
            return specialties
              .map((specialty) => ({ ...specialty, specialties: subspecialtyGroups[specialty.id] }))
              .filter((s) => !!s.specialties);
          })
        )
      )
    );
  }

  getAllSpecialtyIds() {
    return this.store.pipe(select(selectAllSpecialtyIds));
  }

  getAllOptions() {
    return this.store.pipe(select(selectAllSpecialtyOptions));
  }

  getSpecialtyOptionsWithSkipOption(professions: number[]) {
    return this.getSpecialtyOptionsForProfession(of(professions), of(null), of(null));
  }

  getAllSpecialtySubspecialtyOptions() {
    return this.getAllWithSubSpecialties().pipe(select(getAllSpecialtySubspecialtyOptions));
  }

  getSpecialtyNamesByIds(specialtyIds$: Observable<number[]>) {
    return specialtyIds$.pipe(
      mergeMap((specialtyIds) => this.store.pipe(select(selectSpecialtyNamesByIds(specialtyIds))))
    );
  }

  getSpecialtyOptions(specialtyId: number = null, filterIds = null, selectedSubSpecialty: number) {
    return this.preferredProfessionSpecialtyService
      .getAllSpecialtyIds()
      .pipe(
        switchMap((preferredSubspecialtyIds) =>
          this.getAllWithSubSpecialties().pipe(
            select(
              selectSubSpecialtyOptions(
                [
                  ...(intersectNonEmpty(preferredSubspecialtyIds, filterIds) as number[]),
                  selectedSubSpecialty,
                ],
                specialtyId
              )
            )
          )
        )
      );
  }

  getSpecialtyOptionsForProfession(
    selectedProfessionIds$: Observable<number[]>,
    selectedSpecialtyId$: Observable<number>,
    selectedSubSpecialtyId$: Observable<number>
  ): Observable<ISelectGroupOption[]> {
    return combineLatest([
      selectedProfessionIds$,
      selectedSpecialtyId$,
      selectedSubSpecialtyId$,
    ]).pipe(
      switchMap(
        ([selectedProfessionIds, selectedSpecialtyId, selectedSubSpecialtyId]: [
          number[],
          number,
          number
        ]) =>
          this.professionSpecialtyService
            .getSpecialtyIdsForProfessions(selectedProfessionIds)
            .pipe(
              map((specialtyIdsForProfession: number[]): [number, number[], number] => [
                selectedSpecialtyId,
                specialtyIdsForProfession,
                selectedSubSpecialtyId,
              ])
            )
      ),
      switchMap((optionFilters) => this.getSpecialtyOptions(...optionFilters))
    );
  }

  getSpecialtyBySubSpecialtyId(subSpecialtyId: number) {
    return this.getSubSpecialty(subSpecialtyId).pipe(
      mergeMap((subSpecialty) => this.getOne(subSpecialty.category))
    );
  }

  getSubSpecialty(subSpecialtyId: number) {
    return this.subSpecialtyService.getOne(subSpecialtyId);
  }

  getAllSubSpecialties() {
    return this.subSpecialtyService.getAll();
  }

  fetch() {
    return this.loadAllPages('default', {});
  }
}
