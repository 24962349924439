import { Injectable } from '@angular/core';
import { Action, select, Store } from '@ngrx/store';
import { difference, intersection, isNil, reduce } from 'lodash-es';
import { combineLatest, merge, Observable, of } from 'rxjs';
import { filter, first, map, mergeMap, switchMap } from 'rxjs/operators';

import { FilterStateService } from '@locumsnest/core';
import { MicroAppService } from '@locumsnest/core/src/lib/micro-app/micro-app.service';
import { distinctCollectionUntilChangedByValues } from '@locumsnest/core/src/lib/ngrx/operators';

import { PRODUCT_CODES } from '../../core/constants';
import { IPreferredProfessionSpecialtyEntity } from '../../interfaces/api/preferred-profession-specialty-entity';
import { IProfessionSpecialtyEntity } from '../../interfaces/api/profession-specialty-entity';
import { ProfessionSpecialtyService } from '../../profession-specialty/+state/profession-specialty.service';
import { SubSpecialtyService } from '../../sub-specialty/+state/sub-specialty.service';
import { IPreferredProfessionSpecialtyWithSpecialty } from './../../interfaces/api/preferred-profession-specialty-entity';
import { combineSpecialtiesTransformFn } from './interfaces';
import { loadingAdapter } from './preferred-profession-specialty.adapter';
import {
  PreferredProfessionSpecialtyPaginationMessages,
  ResetPreferredProfessionSpecialtyPaginationMessage,
  UpsertMultipleMessage,
  UpsertPreferredProfessionSpecialtyPageMessage,
} from './preferred-profession-specialty.messages';
import { PreferredProfessionSpecialtyPersistenceService } from './preferred-profession-specialty.persistence.service';
import {
  preferredProfessionSpecialtyPaginationSelectors,
  selectAllPreferredProfessionSpecialtiesTemp,
  selectPreferredProfessionSpecialtyEntityState,
} from './preferred-profession-specialty.selectors';
import * as tempMessages from './temp/preferred-profession-specialty.messages';

@Injectable({
  providedIn: 'root',
})
export class PreferredProfessionSpecialtyService extends FilterStateService<
  IPreferredProfessionSpecialtyEntity,
  UpsertPreferredProfessionSpecialtyPageMessage,
  ResetPreferredProfessionSpecialtyPaginationMessage,
  UpsertMultipleMessage
> {
  protected readonly scope = [
    PRODUCT_CODES.MATCH,

    PRODUCT_CODES.LINK,

    PRODUCT_CODES.INTELLIGENCE,

    PRODUCT_CODES.COMMUNITY,
  ];
  constructor(
    protected store: Store,
    protected persistenceService: PreferredProfessionSpecialtyPersistenceService,
    protected subspecialtyService: SubSpecialtyService,
    protected professionSpecialtyService: ProfessionSpecialtyService,
    protected microAppService: MicroAppService
  ) {
    super();
  }

  get paginationMessages() {
    return PreferredProfessionSpecialtyPaginationMessages;
  }

  get paginationSelectors() {
    return preferredProfessionSpecialtyPaginationSelectors;
  }

  get entityStateSelector() {
    return selectPreferredProfessionSpecialtyEntityState;
  }

  get upsertMultipleMessage() {
    return UpsertMultipleMessage;
  }

  get loadingMessages() {
    return loadingAdapter.getMessages();
  }
  getAllTemp() {
    return this.isFilterEnabled$.pipe(
      switchMap((isFilterEnabled) => {
        if (isFilterEnabled) {
          return this.store.pipe(select(selectAllPreferredProfessionSpecialtiesTemp));
        }

        return of<IPreferredProfessionSpecialtyEntity[]>([]);
      })
    );
  }
  combineSpecialties(): combineSpecialtiesTransformFn {
    return ([preferredProfessionSpecialties, professionSpecialties]: [
      IPreferredProfessionSpecialtyEntity[],
      IProfessionSpecialtyEntity[]
    ]) =>
      preferredProfessionSpecialties
        .map((preferredProfessionSpecialty) => {
          const professionSpecialty = professionSpecialties.find(
            (profSpecialty) => profSpecialty.id === preferredProfessionSpecialty.professionSpecialty
          );

          return {
            ...preferredProfessionSpecialty,
            professionSpecialty,
          };
        })
        .filter(({ professionSpecialty }) => !isNil(professionSpecialty));
  }

  filterProfession(professionId: number) {
    return (
      prefProfessionSpecialties: IPreferredProfessionSpecialtyEntity<IProfessionSpecialtyEntity>[]
    ) =>
      prefProfessionSpecialties.filter(
        ({ professionSpecialty }) => professionSpecialty.profession === professionId
      );
  }

  getFilterProfessionTransform(professionId) {
    return map(this.filterProfession(professionId));
  }

  getAllTempWithSpecialty(): Observable<IPreferredProfessionSpecialtyWithSpecialty[]> {
    return combineLatest([
      this.getAllTemp(),
      this.professionSpecialtyService.getAllAfterLoading(),
    ]).pipe(map(this.combineSpecialties()));
  }

  getAllTempWithSpecialtyForProfession(professionId) {
    return this.getAllTempWithSpecialty().pipe(this.getFilterProfessionTransform(professionId));
  }

  getAllWithSpecialty(): Observable<IPreferredProfessionSpecialtyWithSpecialty[]> {
    return combineLatest([
      this.getAllAfterLoading(),
      this.professionSpecialtyService.getAllAfterLoading(),
    ]).pipe(map(this.combineSpecialties()));
  }

  getAllProfessionSpecialtyIdsAfterLoading() {
    return this.getAllAfterLoading().pipe(
      map((entities) => entities.map(({ professionSpecialty }) => professionSpecialty))
    );
  }

  getAllAfterLoadingWithSpecialty(): Observable<IPreferredProfessionSpecialtyWithSpecialty[]> {
    return combineLatest([
      this.getAllAfterLoading(),
      this.professionSpecialtyService.getAllAfterLoading(),
    ]).pipe(map(this.combineSpecialties()));
  }

  getPreferredProfessionSpecialtiesForProfessions(preferredProfessions: number[]) {
    return combineLatest([
      this.getAllAfterLoadingWithSpecialty().pipe(
        map((entities) => entities.map(({ professionSpecialty }) => professionSpecialty))
      ),
      this.professionSpecialtyService.getAllAfterLoading(),
    ]).pipe(
      map(([preferredProfessionSpecialties, allProfessionSpecialties]) => {
        const preferredProfessionSpecialtyProfessions = preferredProfessionSpecialties.map(
          ({ profession }) => profession
        );
        const fullProfessions = difference(
          preferredProfessions,
          preferredProfessionSpecialtyProfessions
        );
        return [
          ...preferredProfessionSpecialties,
          ...allProfessionSpecialties.filter(({ profession }) =>
            fullProfessions.includes(profession)
          ),
        ];
      })
    );
  }

  getPreferredProfessionSpecialtyIdsForProfessions(preferredProfessions: number[]) {
    return this.getPreferredProfessionSpecialtiesForProfessions(preferredProfessions).pipe(
      map((professionSpecialties) => professionSpecialties.map(({ id }) => id)),
      distinctCollectionUntilChangedByValues<number>()
    );
  }

  getAllTempSpecialtyIds() {
    return this.getAllTempWithSpecialty().pipe(
      map((specialties) => specialties.map(({ professionSpecialty }) => professionSpecialty))
    );
  }

  getAllSpecialtyIds() {
    return this.getAllAfterLoadingWithSpecialty().pipe(
      map((specialties) =>
        specialties.map((preferredProfessionSpecialty) => {
          const { professionSpecialty } = preferredProfessionSpecialty;
          const result = professionSpecialty.specialty;

          return result;
        })
      )
    );
  }

  getAllSpecialtyIdsAfterLoading() {
    return this.getAllSpecialtyIds();
  }

  bulkCreate(professionSpecialties: { professionSpecialty: number }[]) {
    return this.persistenceService.bulkCreate(professionSpecialties).pipe(
      map(
        (response: IPreferredProfessionSpecialtyEntity[]) =>
          new tempMessages.AddMultipleMessage({
            entities: response,
          })
      )
    );
  }

  hasTempCategory(specialtyCategoryId: number, professionId: number): Observable<boolean> {
    return this.getAllTempByCategoryWithSpecialty(specialtyCategoryId, professionId).pipe(
      mergeMap((preferredProfessionSpecialties) =>
        this.professionSpecialtyService
          .getByCategoryId(specialtyCategoryId, professionId)
          .pipe(
            map((professionSpecialties) =>
              reduce(
                professionSpecialties,
                (allFound, currentProfessionSpecialty) =>
                  allFound &&
                  !isNil(
                    preferredProfessionSpecialties.find(
                      ({ professionSpecialty }) =>
                        professionSpecialty.id === currentProfessionSpecialty.id
                    )
                  ),
                true
              )
            )
          )
      )
    );
  }

  getAllTempByCategoryWithSpecialty(
    specialtyCategoryId: number,
    professionId: number
  ): Observable<IPreferredProfessionSpecialtyWithSpecialty[]> {
    return this.getAllTempWithSpecialtyForProfession(professionId).pipe(
      mergeMap((preferredProfessionSpecialties) =>
        this.subspecialtyService
          .getAllByCategory(specialtyCategoryId)
          .pipe(
            map((specialties) =>
              preferredProfessionSpecialties.filter(({ professionSpecialty }) =>
                specialties.find((s) => professionSpecialty.specialty === s.id)
              )
            )
          )
      )
    );
  }

  getAllTempByCategory(
    specialtyCategoryId: number,
    professionId: number
  ): Observable<IPreferredProfessionSpecialtyEntity[]> {
    return this.getAllTempByCategoryWithSpecialty(specialtyCategoryId, professionId).pipe(
      map((preferredProfessionSpecialties) =>
        preferredProfessionSpecialties.map((preferredProfessionSpecialty) => ({
          ...preferredProfessionSpecialty,
          professionSpecialty: preferredProfessionSpecialty.professionSpecialty.id,
        }))
      )
    );
  }

  getAllTempProfessionSpecialtyIds() {
    return this.getAllTemp().pipe(
      map((entities) => entities.map(({ professionSpecialty }) => professionSpecialty))
    );
  }

  getAllTempProfessionSpecialtyIdsByProfession(professionId: number) {
    return combineLatest([
      this.professionSpecialtyService.getActiveIdsByProfessionId(professionId),
      this.getAllTempProfessionSpecialtyIds(),
    ]).pipe(map(([available, preferred]) => intersection(available, preferred)));
  }

  getAllTempIdsByProfession(professionId: number) {
    return combineLatest([
      this.professionSpecialtyService.getActiveIdsByProfessionId(professionId),
      this.getAllTemp(),
    ]).pipe(
      map(([available, preferred]) =>
        preferred
          .filter(({ professionSpecialty }) => available.includes(professionSpecialty))
          .map(({ id }) => id)
      )
    );
  }

  getInexistentByProfession(professionId: number) {
    return combineLatest([
      this.professionSpecialtyService.getActiveIdsByProfessionId(professionId),
      this.getAllTempProfessionSpecialtyIds(),
    ]).pipe(map(([available, preferred]) => difference(available, preferred)));
  }

  getInexistentByProfessionList(professionIdList: number[]) {
    return combineLatest([
      this.professionSpecialtyService.getActiveIdsByProfessionIdList(professionIdList),
      this.getAllTempProfessionSpecialtyIds(),
    ]).pipe(map(([available, preferred]) => difference(available, preferred)));
  }

  deleteSpecialtyCategorySubSpecialties(
    specialtyCategoryId: number,
    professionId: number,
    persist?: boolean
  ) {
    return this.getAllTempByCategory(specialtyCategoryId, professionId).pipe(
      first(),
      mergeMap((professionSpecialties) =>
        professionSpecialties.length
          ? this.bulkDeleteSubSpecialties(professionSpecialties, persist)
          : of<Action>()
      )
    );
  }

  bulkDeleteSubSpecialties(
    preferredProfessionSpecialties: IPreferredProfessionSpecialtyEntity[],
    persist?: boolean
  ) {
    const specialties = preferredProfessionSpecialties.map(({ id }) => id);
    return this.bulkDelete({ id: specialties }, persist);
  }

  bulkDelete(subSpecialties: { id: number[] }, persist = true) {
    if (persist === true) {
      return this.persistenceService.bulkDelete(subSpecialties).pipe(
        map(
          () =>
            new tempMessages.DeleteMultipleMessage({
              ids: subSpecialties.id,
            })
        )
      );
    }

    return of(
      new tempMessages.DeleteMultipleMessage({
        ids: subSpecialties.id,
      })
    );
  }

  clear() {
    return this.persistenceService
      .clear({})
      .pipe(map(() => new tempMessages.ResetCollectionMessage({})));
  }

  fetch() {
    return merge(
      this.loadAllPages('default', null),
      this.isLoaded('default').pipe(
        filter((isLoaded) => isLoaded === true),
        first(),
        mergeMap(() =>
          this.getAll().pipe(map((entities) => new tempMessages.SetCollectionMessage({ entities })))
        )
      )
    );
  }
}
