import { Injectable } from '@angular/core';

import { HttpApiPersistenceService } from '@locumsnest/core/src';

import { LocumsNestEndpointConfig } from '../../core/constants';
import { IPreferredSiteEntity } from './../../interfaces/api/preferred-site-entity';

@Injectable({
  providedIn: 'root',
})
export class PreferredSitePersistenceService extends HttpApiPersistenceService<
  LocumsNestEndpointConfig,
  IPreferredSiteEntity
> {
  protected readonly endpoint = 'preferredSite';
}
