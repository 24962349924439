import { createEntityAdapter, EntityAdapter } from '@locumsnest/core/src/lib/ngrx/cached-state';
import { ISpecialtyEntity } from '../../interfaces/api/specialty-entity';
import {
  createPaginatedStateAdapter,
  MessageableFactory,
  SignalableFactory,
} from '@locumsnest/core';
import { createLoadingStateAdapter } from '@locumsnest/core/src/lib/adapters/loading-state-adapter';

export const messageableFactory = MessageableFactory.forFeature<'Permission'>('Permission');
export const signalableFactory = SignalableFactory.forFeature<'Permission'>('Permission');
export function sortByName(a: ISpecialtyEntity, b: ISpecialtyEntity): number {
  return a.title.localeCompare(b.title);
}

export const adapter: EntityAdapter<ISpecialtyEntity> = createEntityAdapter<ISpecialtyEntity>({
  sortComparer: sortByName,
});

export const initialState = adapter.getInitialState({
  // additional entity state properties
  selectedSpecialtyId: null,
});

export const paginationAdapter = createPaginatedStateAdapter(
  signalableFactory,
  messageableFactory,
  adapter
);
export const loadingAdapter = createLoadingStateAdapter<'Permission'>(messageableFactory);
