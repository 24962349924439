import { Injectable } from '@angular/core';
import { Action, select, Store } from '@ngrx/store';
import { isNil, isNumber, isString } from 'lodash-es';
import {
  combineLatest,
  concat,
  distinctUntilChanged,
  EMPTY,
  filter,
  first,
  map,
  mergeMap,
  Observable,
  of,
  switchMap,
} from 'rxjs';

import { FilterStateService, Query } from '@locumsnest/core';
import { MicroAppService } from '@locumsnest/core/src/lib/micro-app/micro-app.service';
import { filterNonEmpty } from '@locumsnest/core/src/lib/ngrx/operators';

import { PRODUCT_CODES } from '../../core/constants';
import { ISiteEntity } from '../../interfaces/api/site-entity';
import { ISite } from '../../interfaces/hospital';
import { PreferredSiteService } from '../../preferred-site/+state/preferred-site.service';
import {
  FilterNamespace,
  IFilterGroup,
  IOption,
} from './../../../../../../libs/components/src/lib/interfaces/filter';
import {
  selectAllPreferredSitesIds,
  selectAllPreferredSitesIdsTemp,
} from './../../preferred-site/+state/preferred-site.selectors';
import { ISiteConfigurationFormState } from './interfaces';
import { loadingAdapter } from './site.adapter';
import {
  ResetSitePaginationMessage,
  SitePaginationMessages,
  UpsertMultipleMessage,
  UpsertMultiplePagesMessage,
  UpsertOneMessage,
  UpsertSitePageMessage,
} from './site.messages';
import { SitePersistenceService } from './site.persistence.service';
import {
  getPreferredSites,
  loadingStateSelectors,
  selectAllSiteConfigurations,
  selectPreferredSites,
  selectSiteEntityState,
  selectSiteOptions,
  sitePaginationSelectors,
} from './site.selectors';

@Injectable({
  providedIn: 'root',
})
export class SiteService extends FilterStateService<
  ISiteEntity,
  UpsertSitePageMessage,
  ResetSitePaginationMessage,
  UpsertMultipleMessage
> {
  protected readonly scope = [
    PRODUCT_CODES.MATCH,
    PRODUCT_CODES.LINK,
    PRODUCT_CODES.INTELLIGENCE,
    PRODUCT_CODES.COMMUNITY,
  ];

  constructor(
    protected store: Store,
    protected persistenceService: SitePersistenceService,
    private preferredSiteService: PreferredSiteService,
    protected microAppService: MicroAppService,
  ) {
    super();
  }

  get paginationMessages() {
    return SitePaginationMessages;
  }

  get upsertMessageClass() {
    return UpsertSitePageMessage;
  }
  get clearMessageClass() {
    return ResetSitePaginationMessage;
  }

  get upsertMultiplePagesMessage() {
    return UpsertMultiplePagesMessage;
  }

  get upsertMultipleMessage() {
    return UpsertMultipleMessage;
  }

  get paginationSelectors() {
    return sitePaginationSelectors;
  }

  get entityStateSelector() {
    return selectSiteEntityState;
  }

  get loadingMessages() {
    return loadingAdapter.getMessages();
  }

  loadWithPreferred() {
    return this.load().pipe(
      mergeMap((x) => [of(x), this.preferredSiteService.load()]),
      mergeMap((x) => x),
    );
  }

  loadOneIfNotExists(id) {
    return this.getOneOrNone(id).pipe(
      first(),
      mergeMap((x) => {
        if (!x) {
          return this.loadOne(id);
        }
        return of<Action>();
      }),
    );
  }

  loadManyIfNotExists(ids: number[]): Observable<Action> {
    return this.getEntityDict().pipe(
      switchMap((entities) => {
        const uniqueIds = [...new Set(ids)];
        const idsToLoad: number[] = [];
        uniqueIds.forEach((id) => {
          if (!entities[id]) idsToLoad.push(id);
        });

        if (idsToLoad.length) return this.loadByIds(uniqueIds);
        return EMPTY;
      }),
    );
  }

  loadOne(id: number) {
    return this.persistenceService
      .retrieve(id)
      .pipe(map((entity) => new UpsertOneMessage({ entity })));
  }

  loadByIds(ids: number[]) {
    return concat(
      of(new this.loadingMessages.SetLoadingMessage({})),
      this.fetch({ id: ids }),
      of(new this.loadingMessages.ResetLoadingMessage({})),
    );
  }

  getAllOptions() {
    return this.store.pipe(select(selectSiteOptions));
  }

  getTrustOf(id: number) {
    return this.getOne(id).pipe(
      map((site) => site?.trust),
      distinctUntilChanged(),
    );
  }

  getSiteIdsByTrustId(id: number) {
    return this.getAllAfterLoading().pipe(
      map((sites) => sites.filter((site) => site.trust === id).map((site) => site.id)),
    );
  }

  getOptionsByTrustIds(ids: number[]) {
    return this.getAllAfterLoading().pipe(
      map((sites) =>
        sites
          .filter((site) => ids.includes(site.trust))
          .map((site) => ({
            id: site.id,
            name: site.name,
          }))
          .sort((a, b) => a.name.localeCompare(b.name)),
      ),
    );
  }

  getAllSiteConfigurations() {
    return this.store.pipe(select(selectAllSiteConfigurations));
  }

  getAllSiteConfigurationsAfterLoading() {
    return this.store.pipe(select(loadingStateSelectors.selectLoadingState)).pipe(
      filter((loadingState) => loadingState.isLoaded === true),
      mergeMap(() => this.getAllSiteConfigurations()),
    );
  }

  getPreferredSites() {
    return this.store.pipe(select(selectPreferredSites), filterNonEmpty);
  }

  getPreferredSiteIds() {
    return this.store.pipe(select(selectAllPreferredSitesIds));
  }

  getPreferredSiteOptions(selectedSiteId$?: Observable<number>) {
    if (selectedSiteId$) {
      return combineLatest([
        this.getAllAfterLoading(),
        this.getPreferredSiteIds(),
        selectedSiteId$,
      ]).pipe(
        map(([sites, preferredIds, selectedSiteId]) =>
          getPreferredSites(sites, [selectedSiteId, ...preferredIds]),
        ),
      );
    }
    return combineLatest([this.getAll(), this.getPreferredSiteIds()]).pipe(
      map(([sites, preferredIds]) => getPreferredSites(sites, [...preferredIds])),
    );
  }

  getPreferredSitesIdsTemp() {
    return this.store.pipe(select(selectAllPreferredSitesIdsTemp));
  }

  getSiteOptions() {
    return this.store.pipe(
      select(selectSiteOptions),
      filter((options) => options && options.length > 0),
    );
  }

  fetch(query?: Query) {
    if (isNil(query)) {
      return this.loadAllPages('default', {});
    }
    if (isString(query) || isNumber(query))
      return this.persistenceService
        .retrieve(query)
        .pipe(map((entity) => new UpsertOneMessage({ entity })));
    return this.persistenceService
      .retrieve(query)
      .pipe(map(({ results }) => new UpsertMultipleMessage({ entities: results })));
  }

  importData(sites: ISiteConfigurationFormState[]) {
    return this.persistenceService
      .importData<ISiteConfigurationFormState[], ISite[]>(sites)
      .pipe(map((entities) => new UpsertMultipleMessage({ entities })));
  }

  getFilters() {
    return combineLatest([this.getAll(), this.getPreferredSitesIdsTemp()]).pipe(
      map(([sites, preferredSitesIds]: [ISiteEntity[], number[]]): IFilterGroup => {
        let order = 0;

        const options = sites.map((site) => {
          const option: IOption = {
            id: site.id,
            name: site.name,
            order: order++,
            visible: true,
            selected: preferredSitesIds.includes(site.id),
            parentId: null,
          };

          return option;
        });

        const filterGroup: IFilterGroup = {
          displayName: 'Sites',
          slug: 'Site',
          count: options.filter((x) => x.selected).length,
          options: options.sort((a, b) => (a.name > b.name ? 1 : a.name < b.name ? -1 : 0)),
          namespace: FilterNamespace.default,
        };

        return filterGroup;
      }),
    );
  }
}
