import { Action } from '@ngrx/store';
import { Update } from '@ngrx/entity';
import { IPreferredProfessionSpecialtyEntity } from '../../interfaces/api/preferred-profession-specialty-entity';
import { IPreferredProfessionSpecialtyEntityState } from './interfaces';
import { paginationAdapter } from './preferred-profession-specialty.adapter';

export const PreferredProfessionSpecialtyPaginationMessages = paginationAdapter.getMessages();

export class UpsertPreferredProfessionSpecialtyPageMessage extends PreferredProfessionSpecialtyPaginationMessages.UpsertPageMessage {}
export class UpsertPreferredProfessionSpecialtyMultiplePagesMessage extends PreferredProfessionSpecialtyPaginationMessages.UpsertMultiplePagesMessage {}
export class ResetPreferredProfessionSpecialtyPaginationMessage extends PreferredProfessionSpecialtyPaginationMessages.ResetPaginationMessage {}

export enum PreferredProfessionSpecialtyMessageTypes {
  SET_COLLECTION = '[PreferredProfessionSpecialty][Message] Set Collection',
  ADD_ONE = '[PreferredProfessionSpecialty][Message] Add One',
  UPDATE_ONE = '[PreferredProfessionSpecialty][Message] Update One',
  UPSERT_ONE = '[PreferredProfessionSpecialty][Message] Upsert One',
  DELETE_ONE = '[PreferredProfessionSpecialty][Message] Delete One',
  ADD_MULTIPLE = '[PreferredProfessionSpecialty][Message] Add All',
  DELETE_MULTIPLE = '[PreferredProfessionSpecialty][Message] Delete Many',
  UPSERT_MULTIPLE = '[PreferredProfessionSpecialty][Message] Upsert Many',
  UPDATE_MULTIPLE = '[PreferredProfessionSpecialty][Message] Update Many',
  UPDATE_ENTITY_STATE = '[PreferredProfessionSpecialty][Message] Update Entity State',
}
export class SetCollectionMessage implements Action {
  readonly type = PreferredProfessionSpecialtyMessageTypes.SET_COLLECTION;
  constructor(public payload: { entities: IPreferredProfessionSpecialtyEntity[] }) {}
}

export class AddOneMessage implements Action {
  readonly type = PreferredProfessionSpecialtyMessageTypes.ADD_ONE;

  constructor(public payload: { entity: IPreferredProfessionSpecialtyEntity }) {}
}

export class UpsertOneMessage implements Action {
  readonly type = PreferredProfessionSpecialtyMessageTypes.UPSERT_ONE;

  constructor(public payload: { entity: IPreferredProfessionSpecialtyEntity }) {}
}

export class AddMultipleMessage implements Action {
  readonly type = PreferredProfessionSpecialtyMessageTypes.ADD_MULTIPLE;

  constructor(public payload: { entities: IPreferredProfessionSpecialtyEntity[] }) {}
}

export class UpsertMultipleMessage implements Action {
  readonly type = PreferredProfessionSpecialtyMessageTypes.UPSERT_MULTIPLE;

  constructor(public payload: { entities: IPreferredProfessionSpecialtyEntity[] }) {}
}

export class UpdateOneMessage implements Action {
  readonly type = PreferredProfessionSpecialtyMessageTypes.UPDATE_ONE;

  constructor(public payload: { entity: Update<IPreferredProfessionSpecialtyEntity> }) {}
}

export class UpdateMultipleMessage implements Action {
  readonly type = PreferredProfessionSpecialtyMessageTypes.UPDATE_MULTIPLE;

  constructor(public payload: { entities: Update<IPreferredProfessionSpecialtyEntity>[] }) {}
}

export class DeleteOneMessage implements Action {
  readonly type = PreferredProfessionSpecialtyMessageTypes.DELETE_ONE;

  constructor(public payload: { id: string }) {}
}

export class DeleteMultipleMessage implements Action {
  readonly type = PreferredProfessionSpecialtyMessageTypes.DELETE_MULTIPLE;

  constructor(public payload: { ids: string[] }) {}
}

export class UpdateEntityStateMessage implements Action {
  readonly type = PreferredProfessionSpecialtyMessageTypes.UPDATE_ENTITY_STATE;

  constructor(
    public payload: {
      preferredProfessionSpecialtyTempState: IPreferredProfessionSpecialtyEntityState;
    }
  ) {}
}

export type PreferredProfessionSpecialtyMessages =
  | SetCollectionMessage
  | AddOneMessage
  | UpsertOneMessage
  | AddMultipleMessage
  | UpsertMultipleMessage
  | UpdateOneMessage
  | UpdateMultipleMessage
  | DeleteOneMessage
  | DeleteMultipleMessage
  | UpdateEntityStateMessage;
