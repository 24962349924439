import { Injectable } from '@angular/core';

import { HttpApiPersistenceService, IQueryParams } from '@locumsnest/core/src';

import { LocumsNestEndpointConfig } from '../../core/constants';
import { ISiteEntity } from '../../interfaces/api/site-entity';
import { ICostCentreNumberEntity } from './../../interfaces/api/cost-centre-number-entity';

@Injectable({
  providedIn: 'root',
})
export class SitePersistenceService extends HttpApiPersistenceService<
  LocumsNestEndpointConfig,
  ISiteEntity
> {
  protected readonly endpoint = 'site';

  retrieveCostCentreNumber(site: number, profession: number, specialty: number) {
    const queryParams: IQueryParams = {
      specialty,
      profession,
    };

    return this.retrieve<ICostCentreNumberEntity>(queryParams, {
      pathParams: { sideId: site },
      controllerResource: 'siteCostCentreNumber',
    });
  }
}
