import { createFeatureSelector, createSelector } from '@ngrx/store';

import { ISelectOption } from '@locumsnest/components/src/lib/interfaces';

import { ISiteEntity } from '../../interfaces/api/site-entity';
import { selectAllPreferredSitesIds } from './../../preferred-site/+state/preferred-site.selectors';
import {
  ISiteConfigurationFormState,
  ISiteEntityState,
  ISiteFeatureState,
  ISiteSharedState,
} from './interfaces';
import { loadingAdapter, paginationAdapter } from './site.adapter';
import { selectAll, selectEntities } from './site.reducer';

export const getSiteOptions = (sites: ISiteEntity[]): ISelectOption[] =>
  sites.map((site) => ({ id: site.id, name: site.name }));

export const getPreferredSites = (allSites: ISiteEntity[], preferredSitesIds: number[]) => {
  const filtered = allSites.filter((site) => preferredSitesIds.indexOf(site.id) > -1);

  if (filtered.length > 0) return filtered;
  else return allSites;
};

export const getSiteConfigurations = (state: ISiteEntityState): ISiteConfigurationFormState[] =>
  (state.ids as number[]).map((id: number) => {
    const siteConfiguration: ISiteConfigurationFormState = {
      id: state.entities[id].id,
      name: state.entities[id].name,
    };

    return siteConfiguration;
  });

/**
 * Create Feature Selectors based on the above selectors
 */
export const selectSiteState = createFeatureSelector<ISiteFeatureState & ISiteSharedState>('sites');
export const selectSiteEntityState = createSelector(selectSiteState, (state) => state.entityState);
export const selectSiteEntities = createSelector(selectSiteEntityState, selectEntities);

export const selectAllSites = createSelector(selectSiteEntityState, selectAll);

export const selectSiteOptions = createSelector(selectAllSites, getSiteOptions);

export const selectOneSite = (id: number) =>
  createSelector(selectSiteEntities, (entities) => entities[id]);

export const selectPreferredSites = createSelector(
  selectAllSites,
  selectAllPreferredSitesIds,
  getPreferredSites
);

export const selectPreferredSiteOptions = createSelector(selectPreferredSites, getSiteOptions);

export const selectAllSiteConfigurations = createSelector(
  selectSiteEntityState,
  getSiteConfigurations
);

export const loadingStateSelectors = loadingAdapter.getSelectors(selectSiteState);

export const sitePaginationSelectors = paginationAdapter.paginationSelectors(
  selectSiteState,
  loadingAdapter,
  'pagination',
  'entityState',
  false
);
