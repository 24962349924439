import { Injectable } from '@angular/core';

import { HttpApiPersistenceService } from '@locumsnest/core/src';

import { LocumsNestEndpointConfig } from '../../core/constants';
import { IPreferredProfessionSpecialtyEntity } from './../../interfaces/api/preferred-profession-specialty-entity';

@Injectable({
  providedIn: 'root',
})
export class PreferredProfessionSpecialtyPersistenceService extends HttpApiPersistenceService<
  LocumsNestEndpointConfig,
  IPreferredProfessionSpecialtyEntity
> {
  protected readonly endpoint = 'preferredProfessionSpecialty';

  bulkCreate(preferredProfessionSpecialties) {
    return this.create(preferredProfessionSpecialties, {
      controllerResource: 'preferredSpecialtyBulkCreate',
      skipSerializer: true,
    });
  }

  bulkDelete(queryParams: { id: number[] }) {
    return this.delete(
      null,
      {},
      {
        queryParams,
        controllerResource: 'preferredSpecialtyBulkDelete',
      },
    );
  }
}
