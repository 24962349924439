import { selectAll, selectAllTemp } from './preferred-profession-specialty.reducer';
import { createSelector, createFeatureSelector } from '@ngrx/store';
import {
  IPreferredProfessionSpecialtyFeatureState,
  IPreferredProfessionSpecialtySharedState,
  IPreferredProfessionSpecialtyEntityState,
} from './interfaces';
import { loadingAdapter, paginationAdapter } from './preferred-profession-specialty.adapter';
import { IPreferredProfessionSpecialtyEntity } from '../../interfaces/api/preferred-profession-specialty-entity';

export const getEntityState = (state: { entityState: IPreferredProfessionSpecialtyEntityState }) =>
  state.entityState;

export const getTempState = (state: { tempState: IPreferredProfessionSpecialtyEntityState }) =>
  state.tempState;

export const getPreferredProfessionSpecialtyIds = (
  entities: IPreferredProfessionSpecialtyEntity[]
) => entities.map((entity) => entity.professionSpecialty);

/**
 * Create Feature Selectors based on the above selectors
 */
export const selectPreferredProfessionSpecialtyState = createFeatureSelector<
  IPreferredProfessionSpecialtyFeatureState & IPreferredProfessionSpecialtySharedState
>('preferredProfessionSpecialties');

// Entity State
export const selectPreferredProfessionSpecialtyEntityState = createSelector(
  selectPreferredProfessionSpecialtyState,
  getEntityState
);

export const selectAllPreferredProfessionSpecialties = createSelector(
  selectPreferredProfessionSpecialtyEntityState,
  selectAll
);

export const selectAllPreferredProfessionSpecialtyIds = getPreferredProfessionSpecialtyIds;

// Temp State
export const selectPreferredProfessionSpecialtyTempState = createSelector(
  selectPreferredProfessionSpecialtyState,
  getTempState
);

export const selectAllPreferredProfessionSpecialtiesTemp = createSelector(
  selectPreferredProfessionSpecialtyTempState,
  selectAllTemp
);

export const selectAllPreferredProfessionSpecialtyIdsTemp = getPreferredProfessionSpecialtyIds;

export const loadingStateSelectors = loadingAdapter.getSelectors(
  selectPreferredProfessionSpecialtyState
);

export const preferredProfessionSpecialtyPaginationSelectors =
  paginationAdapter.paginationSelectors(
    selectPreferredProfessionSpecialtyState,
    loadingAdapter,
    'pagination',
    'entityState',
    false
  );
