import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { isNil } from 'lodash-es';
import { merge, of } from 'rxjs';
import { filter, first, map, mergeMap, switchMap } from 'rxjs/operators';

import { FilterStateService } from '@locumsnest/core';
import { MicroAppService } from '@locumsnest/core/src/lib/micro-app/micro-app.service';

import { PRODUCT_CODES } from '../../core/constants';
import {
  IPreferredSiteEntity,
  IPreferredSitePostEntity,
} from '../../interfaces/api/preferred-site-entity';
import { loadingAdapter } from './preferred-site.adapter';
import {
  PreferredSitePaginationMessages,
  ResetPreferredSitePaginationMessage,
  UpsertMultipleMessage,
  UpsertPreferredSitePageMessage,
} from './preferred-site.messages';
import { PreferredSitePersistenceService } from './preferred-site.persistence.service';
import {
  loadingStateSelectors,
  preferredSitePaginationSelectors,
  selectAllPreferredSitesIds,
  selectAllPreferredSitesTemp,
  selectPreferredSiteEntityState,
} from './preferred-site.selectors';
import * as tempMessages from './temp/preferred-site.messages';

@Injectable({
  providedIn: 'root',
})
export class PreferredSiteService extends FilterStateService<
  IPreferredSiteEntity,
  UpsertPreferredSitePageMessage,
  ResetPreferredSitePaginationMessage,
  UpsertMultipleMessage
> {
  protected readonly scope = [
    PRODUCT_CODES.MATCH,
    PRODUCT_CODES.LINK,
    PRODUCT_CODES.INTELLIGENCE,
    PRODUCT_CODES.COMMUNITY,
  ];

  constructor(
    protected store: Store,
    protected persistenceService: PreferredSitePersistenceService,
    protected microAppService: MicroAppService
  ) {
    super();
  }

  get paginationMessages() {
    return PreferredSitePaginationMessages;
  }

  get paginationSelectors() {
    return preferredSitePaginationSelectors;
  }

  get entityStateSelector() {
    return selectPreferredSiteEntityState;
  }

  get upsertMultipleMessage() {
    return UpsertMultipleMessage;
  }

  get loadingMessages() {
    return loadingAdapter.getMessages();
  }

  getAllTemp(namespace: string = null) {
    const allEntities = isNil(namespace);

    namespace = allEntities ? 'default' : namespace;

    return this.isFilterEnabled$.pipe(
      switchMap((isFilterEnabled) => {
        if (isFilterEnabled) {
          return this.store.pipe(select(selectAllPreferredSitesTemp));
        }

        return of([]);
      })
    );
  }

  getAllIds() {
    return this.store.pipe(select(selectAllPreferredSitesIds));
  }

  getAllIdsAfterLoading() {
    return this.store.pipe(select(loadingStateSelectors.selectLoadingState)).pipe(
      filter((loadingState) => loadingState.isLoaded === true),
      mergeMap(() => this.getAllIds())
    );
  }

  fetch() {
    return merge(
      this.loadAllPages('default', null),
      this.isLoaded('default').pipe(
        filter((isLoaded) => isLoaded === true),
        first(),
        mergeMap(() =>
          this.getAll().pipe(map((entities) => new tempMessages.SetCollectionMessage({ entities })))
        )
      )
    );
  }

  bulkCreate(sites: IPreferredSitePostEntity[]) {
    return this.persistenceService.bulkCreateFilter(sites).pipe(
      map(
        (response: IPreferredSiteEntity[]) =>
          new tempMessages.UpsertMultipleMessage({
            entities: response,
          })
      )
    );
  }

  bulkDelete(sites: { id: number[] }) {
    return this.persistenceService.clear(sites).pipe(
      map(
        () =>
          new tempMessages.DeleteMultipleMessage({
            ids: sites.id,
          })
      )
    );
  }
}
