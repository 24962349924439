import { SiteMessageTypes as MessageTypes, SiteMessages } from './site.messages';
import { ISiteEntityState } from './interfaces';
import { adapter, initialState, loadingAdapter, paginationAdapter } from './site.adapter';
import { combineReducers } from '@ngrx/store';

export * from './interfaces';

const loadingStateReducer = loadingAdapter.createReducer();
export const paginationReducer = paginationAdapter.createReducer();

export function entityReducer(state = initialState, action: SiteMessages): ISiteEntityState {
  switch (action.type) {
    case MessageTypes.ADD_ONE: {
      return adapter.setOne(action.payload.entity, state);
    }

    case MessageTypes.UPSERT_ONE: {
      return adapter.upsertOne(action.payload.entity, state);
    }

    case MessageTypes.SET_COLLECTION: {
      return adapter.setAll(action.payload.entities, state);
    }

    case MessageTypes.ADD_MULTIPLE: {
      return adapter.addMany(action.payload.entities, state);
    }

    case MessageTypes.UPSERT_MULTIPLE: {
      return adapter.upsertMany(action.payload.entities, state);
    }

    case MessageTypes.UPDATE_ONE: {
      return adapter.updateOne(action.payload.entity, state);
    }

    case MessageTypes.UPDATE_MULTIPLE: {
      return adapter.updateMany(action.payload.entities, state);
    }

    case MessageTypes.DELETE_ONE: {
      return adapter.removeOne(action.payload.id, state);
    }

    case MessageTypes.DELETE_MULTIPLE: {
      return adapter.removeMany(action.payload.ids, state);
    }
    default: {
      return state;
    }
  }
}
export function reducer(state, action) {
  return combineReducers({
    entityState: entityReducer,
    entityLoadingState: loadingStateReducer,
    pagination: paginationReducer,
  })(state, action);
}

export const { selectIds, selectEntities, selectAll, selectTotal } =
  paginationAdapter.getEntitySelectors();
