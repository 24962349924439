import { createEntityAdapter, EntityAdapter } from '@locumsnest/core/src/lib/ngrx/cached-state';
import { ISiteEntity } from '../../interfaces/api/site-entity';
import { createLoadingStateAdapter } from '@locumsnest/core/src/lib/adapters/loading-state-adapter';
import { MessageableFactory, SignalableFactory } from '@locumsnest/core/src/lib/ngrx';
import { createPaginatedStateAdapter } from '@locumsnest/core/src/lib/adapters';

export function sortByName(a: ISiteEntity, b: ISiteEntity): number {
  return a.name.localeCompare(b.name);
}

export const adapter: EntityAdapter<ISiteEntity> = createEntityAdapter<ISiteEntity>({
  sortComparer: sortByName,
});

export const messageableFactory = MessageableFactory.forFeature<'Site'>('Site');
export const signalableFactory = SignalableFactory.forFeature<'Site'>('Site');
export const paginationAdapter = createPaginatedStateAdapter(
  signalableFactory,
  messageableFactory,
  adapter
);

export const loadingAdapter = createLoadingStateAdapter<'Site'>(messageableFactory);

export const initialState = adapter.getInitialState({
  // additional entity state properties
  selectedSiteId: null,
});
