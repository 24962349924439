import { IPreferredSiteEntity } from './../../interfaces/api/preferred-site-entity';
import { createEntityAdapter, EntityAdapter } from '@locumsnest/core/src/lib/ngrx/cached-state';
import { createLoadingStateAdapter } from '@locumsnest/core/src/lib/adapters/loading-state-adapter';
import { MessageableFactory, SignalableFactory } from '@locumsnest/core/src/lib/ngrx';
import { createAlertStateAdapter, createPaginatedStateAdapter } from '@locumsnest/core/src';

export const adapter: EntityAdapter<IPreferredSiteEntity> =
  createEntityAdapter<IPreferredSiteEntity>({});

export const messageableFactory = MessageableFactory.forFeature<'PreferredSite'>('PreferredSite');
export const loadingAdapter = createLoadingStateAdapter<'PreferredSite'>(messageableFactory);
export const signalableFactory = SignalableFactory.forFeature<'PreferredSite'>('PreferredSite');
export const paginationAdapter = createPaginatedStateAdapter(
  signalableFactory,
  messageableFactory,
  adapter
);

export const alertStateAdapter = createAlertStateAdapter(signalableFactory, messageableFactory);
export const { errorHandler, conditionalErrorHandler, alertHandler } =
  alertStateAdapter.getAlertHandlers();
export const { ResetErrorMessage, AlertErrorMessage } = alertStateAdapter.getMessages();

export const initialState = adapter.getInitialState({
  // additional entity state properties
  selectedPreferredSiteId: null,
});
