import { createAlertStateAdapter, createPaginatedStateAdapter } from '@locumsnest/core/src';
import { createLoadingStateAdapter } from '@locumsnest/core/src/lib/adapters/loading-state-adapter';
import { MessageableFactory, SignalableFactory } from '@locumsnest/core/src/lib/ngrx';
import { createEntityAdapter, EntityAdapter } from '@locumsnest/core/src/lib/ngrx/cached-state';
import { IPreferredProfessionSpecialtyEntity } from './../../interfaces/api/preferred-profession-specialty-entity';

export const adapter: EntityAdapter<IPreferredProfessionSpecialtyEntity> =
  createEntityAdapter<IPreferredProfessionSpecialtyEntity>({});

export const messageableFactory = MessageableFactory.forFeature<'PreferredProfessionSpecialty'>(
  'PreferredProfessionSpecialty'
);
export const loadingAdapter =
  createLoadingStateAdapter<'PreferredProfessionSpecialty'>(messageableFactory);
export const signalableFactory = SignalableFactory.forFeature<'PreferredProfessionSpecialty'>(
  'PreferredProfessionSpecialty'
);

export const paginationAdapter = createPaginatedStateAdapter(
  signalableFactory,
  messageableFactory,
  adapter
);

export const alertStateAdapter = createAlertStateAdapter(signalableFactory, messageableFactory);
export const { errorHandler, conditionalErrorHandler, alertHandler } =
  alertStateAdapter.getAlertHandlers();
export const { ResetErrorMessage, AlertErrorMessage } = alertStateAdapter.getMessages();

export const initialState = adapter.getInitialState({
  // additional entity state properties
  selectedPreferredProfessionSpecialtyId: null,
});
